@use 'src/theme/colors' as *;

.pull-to-refresh-ptr {
  background-color: map-get($viac-colors, viac-primary-30);
}

.pull-to-refresh-animation-location {
  width: 100%;
}

.pull-to-refresh-icon {
  font-size: 1.4rem;
}

.pull-to-refresh-icon > * {
  width: 1.875rem;
  height: 1.875rem;
}
