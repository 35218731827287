@use 'src/theme/assets' as *;
@use 'src/theme/colors' as *;

.welcome-container {
  background-image: asset-url('images/welcome/welcome-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;

  viac-swiper-container,
  viac-swiper-slide {
    height: 100%;
  }

  viac-swiper-container {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-bottom: 1rem;
  }

  .slide-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      color: map-get($viac-colors, viac-neutral-0);
      margin-top: 1.875rem;
      min-height: 3.1875rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .graphic-container {
      position: relative;
      height: 0;
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  viac-animation {
    display: block;
    overflow: visible;
    position: absolute;
    top: 0;
    bottom: 1rem;
    left: 2.5rem;

    // stylelint-disable selector-max-compound-selectors
    ng-lottie > div,
    ng-lottie > div > svg {
      height: 100%;
      width: auto !important; // stylelint-disable-line declaration-no-important
    }

    // stylelint-enable selector-max-compound-selectors
  }
}
