@use 'src/theme/breakpoints' as *;
@use 'src/theme/pages' as *;
@use 'src/theme/variables' as *;

img.new-sticker {
  position: absolute;
  width: 2.75rem;
  top: -0.5rem;
  right: -0.5rem;
}

.product-overview-card {
  background-color: map-get($viac-colors, viac-neutral-0);
  border-radius: 0.75rem;
  padding: $product-card-margin-sides-small 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 15px map-get($viac-colors, viac-neutral-100-20-percent);
  position: relative;

  &:hover,
  &:focus {
    box-shadow: 0 4px 40px map-get($viac-colors, viac-neutral-100-20-percent);
  }

  > * {
    margin: 0;
  }

  h3 {
    color: map-get($viac-colors, viac-neutral-100);
  }

  .icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    color: map-get($viac-colors, viac-primary-100);

    @include media-breakpoint-up(md) {
      width: 2rem;
      height: 2rem;
    }
  }

  .card-description {
    flex-grow: 1;

    *:last-child {
      margin-bottom: 0;
    }

    p {
      color: map-get($viac-colors, viac-secondary-dark-60);
    }
  }

  > .card-right,
  > .card-right h3 {
    color: map-get($viac-colors, viac-secondary-dark-60);
    margin: 0;
  }

  > .card-right .add-icon {
    height: 1.25rem;
    width: 1.25rem;
    color: map-get($viac-colors, viac-secondary-dark-60);

    @include media-breakpoint-up(md) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.add-product-card {
    background-color: rgba(map-get($viac-colors, viac-neutral-0), 0.75);

    &:hover,
    &:focus {
      background-color: map-get($viac-colors, viac-neutral-0);
    }
  }

  &.benefit-card {
    background-color: map-get($viac-colors, viac-neutral-100);

    @include media-breakpoint-down(md) {
      padding: 1rem;
      min-height: 3.5rem;
      gap: 0.5rem;

      .new-sticker {
        width: 2rem;
      }
    }

    h3,
    .icon {
      color: map-get($viac-colors, viac-neutral-0);
    }

    .icon-and-badge {
      $badge-large-size: 0.875rem;
      $badge-small-size: 0.75rem;
      $badge-border-size: 0.125rem;

      position: relative;

      > svg {
        margin: 0;
      }

      .notification-number {
        display: grid;
        place-items: center;
        position: absolute;
        top: calc(($badge-small-size + $badge-border-size) / -2);
        right: calc(($badge-small-size + $badge-border-size) / -2);
        width: calc($badge-small-size + $badge-border-size);
        height: calc($badge-small-size + $badge-border-size);
        background-color: map-get($viac-colors, viac-error-100);
        border-radius: 50%;
        border: $badge-border-size solid map-get($viac-colors, viac-neutral-100);
        color: map-get($viac-colors, viac-neutral-0);
        font-size: 0.625rem;
        line-height: 100%;

        /* stylelint-disable-next-line max-nesting-depth */
        @include media-breakpoint-up(md) {
          top: calc(($badge-large-size + $badge-border-size) / -2);
          right: calc(($badge-large-size + $badge-border-size) / -2);
          width: calc($badge-large-size + $badge-border-size);
          height: calc($badge-large-size + $badge-border-size);
        }
      }
    }
  }
}
